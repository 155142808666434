import React from 'react'
import PropTypes from 'prop-types'
import imagejpg from '../image.jpg'
import Text from '../libs/components/text'


function Image(props) {
  return (
    <div className="min-h-screen flex flex-col items-center p-16">

      <Text type='heading'>Image</Text>

      <a href='https://www.google.com/search?q=puppies&sxsrf=ALiCzsbMRV-waTeO_gexNmK5YPPcUMIgZg:1654695448814&source=lnms&tbm=isch&sa=X&ved=2ahUKEwidlcDz_J34AhWqtYQIHYfWDboQ_AUoAXoECAIQAw&biw=1440&bih=789&dpr=2'>
        <img src={imagejpg} className="w-96 h-96 mt-16 rounded-lg" alt="logo" />
      </a>
    </div>
  )
}

export default Image
