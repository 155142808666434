import './App.css';

function App() {
  return (
    <div className="App min-h-screen flex flex-col items-center">
      <div className='p-16'>
        <p className='text-white text-4xl '>Elements of Multimedia</p>
        <p className='text-white text-sm'>By Mundia Mundia (2018232096)</p>
      </div>

      <div className='w-full bg-white h-px' />

      <div className='p-16 grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-16'>

        <div className='flex items-center justify-center flex-col'>
          <a href='/animation' className=''> <p className='text-white hover:text-blue-400'>Animation</p></a>
        </div>

        <div className='flex items-center justify-center flex-col'>
          <a href='/image'>  <p className='text-white hover:text-blue-400'>Image</p></a>
          
        </div>

        <div className='flex items-center justify-center flex-col'>
          <a href='/text'> <p className='text-white hover:text-blue-400'>Text</p></a>
          <div className='w-64 h-64 flex justify-center items-center'>
            <p className='text-sm'>text is an example of Multimedia. <br /> This is the section that showcases text <br />  in action as an element of Multimedia</p>
          </div>
        </div>

        <div className='flex items-center justify-center flex-col'>
          <a href='/audio'> <p className='text-white hover:text-blue-400' >Audio</p></a>
          <div className='w-64 h-64 flex justify-center items-center'>

            <iframe title='sound' width="300" height="100" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1276358503&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
          </div>
        </div>


        <div className='flex items-center justify-center flex-col'>
          <a href='/video'> <p className='text-white hover:text-blue-400'>Video</p></a>
          <iframe width="300" height="300" src="https://www.youtube.com/embed/t_bsQFEqLks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>      </div>

      </div>
    </div>
  );
}

export default App;
