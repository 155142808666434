import React from 'react'
import PropTypes from 'prop-types'
import Text from '../libs/components/text'
import logo from '../logo.svg';

function Animation(props) {
    return (
        <div className="min-h-screen flex flex-col items-center p-16">

            <Text type='heading' >Animation</Text>
            
            <img src={logo} className="App-logo w-92 h-92" alt="logo" />

        </div>
    )
}

export default Animation
