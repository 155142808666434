import React from 'react'
import PropTypes from 'prop-types'
import Text from '../libs/components/text'

function Audio(props) {
  return (
    <div className="min-h-screen flex flex-col items-center p-16">

    <Text type='heading'>Audio</Text>
    
    <iframe className='mt-24' title='sound' width="800" height="100" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1276358503&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    </div>
  )
}

export default Audio
