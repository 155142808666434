import React from 'react'
import PropTypes from 'prop-types'
import Text from '../libs/components/text'

function Video(props) {
  return (
    <div className="min-h-screen flex flex-col items-center p-16">

    <Text type='heading'>Video</Text>
    
    <iframe className='mt-16' width="600" height="500" src="https://www.youtube.com/embed/t_bsQFEqLks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>     
    </div>
  )
}

export default Video
